
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/DMSerifDisplayRegular.ttf"); font-family : "DM Serif Display"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PoppinsSemiBold.ttf"); font-family : "Poppins"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/InterRegular.ttf"); font-family : "Inter"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/InterSemiBold.ttf"); font-family : "Inter"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/InterBold.ttf"); font-family : "Inter"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/LatoRegular.ttf"); font-family : "Lato"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/LatoBold.ttf"); font-family : "Lato"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/ManropeMedium.ttf"); font-family : "Manrope"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/NunitoRegular.ttf"); font-family : "Nunito"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/LemonadaRegular.ttf"); font-family : "Lemonada"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/DMSansBold.ttf"); font-family : "DM Sans"; font-weight : 700;  }