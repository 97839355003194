:root { 
--black_900_7f:#0000007f; 
--white_A700_7f:#ffffff7f; 
--teal_700_01:#0f835c; 
--deep_orange_A100:#eba178; 
--blue_A200:#5b92ff; 
--red_400:#e44848; 
--gray_800_01:#4c4c4c; 
--light_blue_900:#014e81; 
--blue_gray_800_bf:#2d4059bf; 
--black_900_01:#000100; 
--yellow_800:#f29c1f; 
--teal_700:#118768; 
--amber_400_19:#f0c41919; 
--deep_orange_900:#b2621d; 
--purple_900:#3e005b; 
--gray_200_01:#e9e8e7; 
--blue_gray_700:#535353; 
--yellow_900_19:#f6871419; 
--blue_gray_900:#170f49; 
--gray_900_0f:#1212120f; 
--pink_300:#ef5da8; 
--black_900_02:#030229; 
--gray_600:#757171; 
--pink_50:#fce4e9; 
--gray_400:#c4c4c4; 
--blue_gray_100:#ccccc9; 
--orange_A200:#d4a14a; 
--blue_gray_300:#94acb7; 
--gray_800:#3d3d3d; 
--lime_900:#8b4d1c; 
--blue_gray_500:#6e6b8f; 
--gray_400_7f:#c4c4c47f; 
--amber_300:#ffd460; 
--gray_200:#ebebeb; 
--orange_400:#f99c2d; 
--black_900_0c:#0000000c; 
--blue_400_0c:#3faaf40c; 
--blue_50:#ebf9fe; 
--black_900_99:#00000099; 
--gray_400_01:#b7b6b5; 
--amber_400_05:#f0c41905; 
--blue_300:#6ac5ec; 
--blue_100:#baccfc; 
--cyan_50:#d5f1fc; 
--black_900_19:#00000019; 
--white_A700:#ffffff; 
--black_900_99_01:#03022999; 
--purple_A700_19:#b429f919; 
--pink_400_42:#d24c6542; 
--red_400_01:#e25151; 
--deep_purple_300:#9d76ed; 
--black_900:#000000; 
--purple_A700:#b429f9; 
--yellow_900:#f68714; 
--blue_gray_800:#2d4059; 
--blue_gray_700_bf_01:#545567bf; 
--purple_50:#f2e0f9; 
--gray_900_02:#202020; 
--purple_A700_05:#b429f905; 
--gray_600_02:#696e7c; 
--blue_gray_200:#aac4d0; 
--gray_500:#a9a9aa; 
--gray_600_01:#787486; 
--amber_400:#f0c419; 
--indigo_50:#dfe8f8; 
--amber_600:#ffb700; 
--lime_800:#c68a21; 
--gray_900:#1c1c1b; 
--gray_900_01:#1a1a1a; 
--purple_A700_19_01:#ad31f919; 
--amber_300_01:#fad364; 
--gray_300:#d9dbe9; 
--gray_100:#eff3fe; 
--amber_300_7f:#ffd4607f; 
--yellow_900_05:#f6871405; 
--orange_400_01:#fa9622; 
--blue_100_01:#bfdce9; 
--light_blue_400_05:#26c5f305; 
--blue_gray_700_bf:#535353bf; 
}